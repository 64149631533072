<template>
  <div class="box">
    <div class="message-box">
      <div
        class="message"
        v-for="(l, index) in list"
        :key="index"
        :style="myId == l.sender ? 'flex-direction:row-reverse' : ''"
      >
        <!-- <img src="@/img/consultant.png" class="head" /> -->
        <img
          :src="
            l.sender ? my.userPhotoFullPath : consultant.profilePhotoFullPath
          "
          class="head"
        />
        <div
          class="nr"
          :style="l.sender ? 'background: #fbecb3' : ''"
          v-if="l.content"
        >
          {{ l.content }}
        </div>
        <div class="nr" v-else>
          <div
            v-for="a in l.arr"
            :key="a.aid"
            :style="a.qtext ? 'color: #528fff' : ''"
            @click="getAnswer(a.aid, a.qtext)"
          >
            {{ a.qtext ? a.qtext : a.ans }}
          </div>
        </div>
      </div>
      <div ref="scrollEnd"></div>
    </div>
    <div class="bottom">
      <input type="text" class="val" v-model="val" />
      <div class="send" @click="send">发送</div>
    </div>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      chartData: {},
      list: [],
      myId: "",
      consultant: {},
      my: {},
      val: "",
    };
  },
  props: ["chatVal"],
  watch: {
    chatVal: function () {
      this.$http.get(url.getAnswer).then((res) => {
        console.log(res.data.result.ans, 888888);
        if (res.code == 200) {
          this.list.push({ arr: res.data.result.ans });
          console.log(this.list, 55555);
        }
      });
    },
  },
  created() {
    this.myId = localStorage.getItem("wql_user_id");
    this.chartData = JSON.parse(localStorage.getItem("msg"));
    this.getConsultant();
    this.getMy();
  },
  methods: {
    //获取顾问信息
    getConsultant() {
      this.$http.get(url.consultant).then((res) => {
        this.consultant = res.data;
        if (!this.consultant.profilePhotoFullPath) {
          this.consultant.profilePhotoFullPath = require("@/img/my/user-photo.png");
        }
      });
    },
    //获取自己信息
    getMy() {
      this.$http.get(url.userInfo).then((res) => {
        this.my = res.data;
        if (!this.my.userPhotoFullPath) {
          this.my.userPhotoFullPath = require("@/img/my/user-photo.png");
        }
      });
    },

    //发送消息
    send() {
      this.$http
        .post(
          url.question +
            `?question=${this.val}&limit=5&focus=0&questions=${this.val}`
        )
        .then((res) => {
          if (res.code == 200) {
            this.list.push({ content: this.val, sender: this.myId });
            this.val = "";
            this.$nextTick(() => {
              this.$refs.scrollEnd.scrollIntoView();
            });
          }
        });
    },
    //点击问题
    getAnswer(aid, qtext) {
      if (aid) {
        this.$http
          .post(url.autoansid + `?aid=${aid}&question=${qtext}&focus=0`)
          .then((res) => {
            if (res.code == 200) {
              this.list.push({ content: res.data.result.ans });
            }
          });
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f5f5f5;
  min-height: 100vh;
  .message-box {
    width: 100%;
    height: calc(100vh - 110px);
    // margin-top: 55px;
    overflow-y: scroll;
    padding-top: 55px;
    padding-bottom: 20px;
    // padding-bottom: 50px;
  }
  .message {
    display: flex;
    margin-top: 15px;
    .head {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin-left: 11px;
    }
    .nr {
      padding: 13px 11px;
      background: #ffffff;
      border-radius: 5px;
      margin-left: 8px;
      font-size: 14px;
      color: #000000;
      max-width: 280px;
    }
  }
  .bottom {
    width: 375px;
    height: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    .val {
      width: 300px;
      height: 34px;
      background: #ffffff;
      border-radius: 17px;
      border: none;
      margin-left: 11px;
      margin-right: 11px;
      font-size: 16px;
      padding: 0 8px;
    }
    .send {
      color: #113fc4;
      font-size: 15px;
      font-weight: bold;
    }
  }
  .back {
    position: relative;
    font-size: 20px;
    right: 10px;
  }
}
</style>
